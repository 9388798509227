@import url('https://fonts.googleapis.com/css?family=Signika:300');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,900');

// importation du fichier _variables.scss
@import "variables";

:root {
  --content-width: 54rem;
}

*,
*:after,
*:before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.hidden{
  display: none;
}

html {
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  line-height: 24pt;
}

h1, h2, h3, h4{
  font-family: 'Signika', sans-serif;
  font-weight: normal;  
}

h1{
  font-size: 22px;
}

ul{
  padding-left: 2em;
  li {
    list-style-image: url('../images/de.jpg');
  }
}

a {
  color: $brandcolor1;
  text-decoration: none;
}

p {
  display: block;
  margin-block-start: 1.5em;
  margin-block-end: 1.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

strong, b {
  font-weight: 700;
}

img {
  max-width: 100%;
}

.page {
  padding: 5vh 5vw 10vh;
}
.page > * {
  max-width: var(--content-width);
  margin: 0 auto;
}

.header {
  margin-bottom: 1.5rem;
}

.header a {
  position: relative;
  text-transform: uppercase;
  font-size: .875rem;
  letter-spacing: .05em;
  padding: .5rem 0;
  font-weight: 700;
}



.header .logo {
  display: block;
  margin-bottom: 1.5rem;
  padding: .5rem 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu{ 
  text-align: right;
  a {
    margin: 0 .75rem;
  }

  a[aria-current] {
    border-bottom: 2px solid #000;
  }
}
@media (min-width: 768px) {
  .hamburger{
    display: none;
  }
}
@media  (max-width: 767px) {
  #hamburger{
    display: block;
    color: $brandcolor1;
    font-size: 14px;
    .menuimg{
      display: block;
      width: 38px;
      height:30px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../images/menu.png");
    }
    .menuimg_close{
      background-image: url("../images/menu_close.png");
    }

  }
  .menu{
    display: none;
    width: 100%;
    text-align: center;
  }
  .header a {
    display: block;
    widows: 100%;
  }
}

@media screen and (min-width: 768px) {
  .header .logo {
    margin-bottom: 0;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
  }
  .menu {
    margin-right: -.75rem;
  }
}
@media (min-width: 768px) and (max-width: 1000px){
  .header{    
    .logo{
      flex-basis: 30%;
    }
    a{
      margin: 0 .2rem;
      font-size: .8rem;
    }
  }
  .menu{
    flex-basis: 70%;
    a {
      margin: 0 .2rem;
    }
  } 
}

main {
  min-height: calc(64vh - 10rem);
}

.intro {
  padding: 1vh 0;

  .text{
    width: $centercol;
    margin:auto;
    text-align: justify;
    color: $brandcolor1;
    font-style: italic;
    font-weight: 600;
    font-size: 145%;
  }

  h1 {
    width: $centercol;
    color: $brandcolor1; 
    margin:auto;
    position: relative;
    margin-bottom: 1rem;
    font-size: calc(1vw + 0.5rem);
    z-index: 1;
  }
  .boximg{
    width: $centercol;
    margin:auto auto 1.5em auto;
  }
}
@media (min-width: 300px) and (max-width: 768px) {
  .intro {
    .text,
    .boximg{
      width: 100%;
    }
    h1 {
      width: 100%;      
      font-size: 1.5em;
    }
  }
}


.tags {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  font-size: .75rem;
  font-weight: 600;
}

.text {
  line-height: 1.5em;
  p,
  figure,
  ul,
  ol {
    margin-bottom: 1.5em;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  & > *:first-child {
    margin-top: 0;
  }
  a {
    position: relative;
    white-space: nowrap;
    font-weight: 500;
    z-index: 1;

  }
  figure {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  img {
    max-width: 100%;
  }
}
.footer {
  padding: 1.5rem 0 2vh;
  background-color: $footercolor;
  .footer-content{
    position: relative;    
    max-width: var(--content-width);
    margin: 0 auto;
    line-height: 1.5em;
    min-height: 180px;
    .footer_img{
      position: absolute;
      left: -180px;
      img{
        width: 150px;
      }
    }
  }

   a {
    display: inline-block;
    font-size: .875rem;
  }
  & > a {
    margin-bottom: 1.5rem;
    border-top: 2px solid #000;
    width: 16.5rem;
    padding-top: .5rem;
  }
  .boxbutton{
    margin-bottom: 1em;
  }
  .copyright{
    float: right;
  }
}
@media (max-width: 1400px) {
  .footer {
    padding: 1.5rem 1.5rem 2vh;
    .footer_img{
      display: none;
    }
  }
}
@media (max-width: 768px) {
  .footer {
    .footer-content{
      text-align: center;
    }
    .copyright{
      float: none;
    }
  }
}

.social a {
  margin: 0 .75rem;
  padding: .5rem 1rem;
  border: 2px solid #000;
  width: 7.5rem;
}
.social a:hover {
  background: #000;
  color: #fff;
}
.banner-citation{  
  padding: 3vh 0 1vh 0;
  background-color: $brandcolor2; 
  display: flex;
  margin-bottom: 3em;
  .leftcol{
    padding: 1.5em;
    width: 50% - ($centercol /2);
  }
 .centercol{
    width: $centercol /2;
    padding: 0 1em 0 0; 
    .citation{
      
      font-size: 100%;
      p{
        margin:0;
      }
    } 
  }
  .rightcol{
    padding-right: 2em;
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    .boximg{
      position: relative;
      order:1;
      flex: none;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: 0 1.5em 0 1.5em;
      img{
        padding-top: 0;
        position: relative;
        top: -10px;
        max-width: 200px;
        height: auto;
      }
    }
    .boxbutton{
      flex: none;
      order:2;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-end;
      .button-email{
        display: inline-block;
      }
    }
  }
   img{    
    max-width: 100%;
  }

  .citation-auteur{
    color: $brandcolor1;
    font-style: italic;
    font-size: 75%;
  }
}
.button-heike{ 
  color: $brandcolor1;
  font-size: 90%;
  .button-heike-elem{
    &:nth-child(2){
      padding-left: 1em;
    }
  }
  p{
    margin:0.2em 0;
  }
}
.button-email{
  padding: 0.2em 2em;
  border-radius: 50px;
  bottom: 0;  
  background-color: $brandcolor1; 
  color: $brandcolor1-invert;
  font-size: 90%;
  border: none;
}
.uniform__potty {
  position: absolute;
  left: -9999px;
}

.uniform-errors{
  padding: 1em;
  margin: 1em 0;  
  border: solid 1px #f44336;
  background-color: #fccfcc;
}

.uniform__captcha_label{
    margin-top: 2em;
}
.uniform__captcha{
    margin-bottom: 2em;
} 
.success{
  padding: 1em;
  margin: 1em 0;  
  border: solid 1px #8BC34A;
  background-color: #eefae1;
}
.field.error {
  border-left: red 4px solid;
}

@media (max-width: 768px) {
  .banner-citation{  
    padding: 1vh 0 ;
    display: block;
    .leftcol, 
    {
      display: none;
      
    }
    .rightcol{
      text-align: center;
      display: block;
      width: 100%;
      padding: 0 2em 2em 0 ;
      .boximg{
        display: none;
      }
      .boxbutton{
        display: block;
      }
    }
    .centercol{
      width: 100%;
      padding: 2em 2em 0 2em;       
    }
  }
 
}